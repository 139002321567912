html,
input,
button {
  font-family: Inter, sans-serif;
}

html {
  color: #242529;
  font-size: 16px;
}

a {
  color: #43aa8b;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #16886b;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
}

body,
ul,
li {
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

.wrapper-small {
  max-width: 480px;
  margin: auto;
}

.container {
  padding: 16px;
}

.header {
  text-align: center;

  &__title {
    margin: 0 0 8px;
    font-size: 36px;
    font-weight: 800;
  }

  &__desc {
    font-size: 16px;
  }
}

.dropzone {
  box-sizing: border-box;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;

  background: rgba(#16886b, 0.25);
  border: 5px dashed #16886b;

  animation: fadein 0.5s;
}

.form-area {
  text-align: center;

  &__desc {
    margin: 0 0 16px;
  }

  &__button {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc;
    background: #eee;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
    font-size: 14px;
    color: initial;
    width: 150px;

    &:hover {
      color: initial;
      background: #ddd;
    }
  }

  &__input {
    display: none;
  }
}

.charts {
  .chart {
    margin: auto;

    &__header {
      font-size: 24px;
      font-weight: 800;
      margin: 0;
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}

.transactions {
  max-width: 480px;
  margin: auto auto 36px;

  &__list {
    list-style: none;
  }

  &__summary {
    cursor: pointer;
    font-size: 14px;

    :not(details[open]) & {
      margin-bottom: 8px;
    }
  }
}

.account-summary {
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.account {
  margin-bottom: 8px;

  &__name {
    font-weight: 600;
  }

  &__bank {
    font-size: 14px;
    color: #737479;
  }
}

.owner {
  margin-bottom: 8px;

  &__row {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 4px;
    }

    > :first-child {
      font-weight: 600;
      width: 20%;
    }

    > :last-child {
      width: 80%;
    }
  }

  &__info-list {
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.transaction {
  font-size: 12px;

  &__info {
    display: flex;
  }

  &__categories {
    margin-top: 4px;
    font-size: 10px;
    padding-left: 80px;

    span:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__type {
    border: 1px solid #c2d7d3;
    background: #eefdf7;
    border-radius: 4px;
    padding: 2px;
  }

  &__category {
    border: 1px solid #ccc;
    background: #eee;
    border-radius: 4px;
    padding: 2px;
  }

  &__date {
    width: 80px;
    color: #737479;
    text-align: left;
  }

  &__desc {
    width: calc(100% - 80px - 70px);
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__amount {
    width: 70px;
    text-align: right;

    &.debit {
      color: #16886b;
    }

    &.credit {
      color: #d4122a;
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.hint {
  color: #737479;
  font-size: 12px;
}

.main {
  flex-grow: 1;
  width: 100%;
}

.header,
.footer {
  flex-shrink: 0;
}

.footer {
  text-align: center;

  .footer__love {
    color: #f94144;
    font-size: 20px;
    transition: color 0.3s;

    position: relative;
    top: 1px;

    &:hover {
      color: #d4122a;
    }
  }
}

[hidden] {
  display: none !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
