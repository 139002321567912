html,
input,
button {
  font-family: Inter, sans-serif;
}

html {
  color: #242529;
  font-size: 16px;
}

a {
  color: #43aa8b;
  text-decoration: none;
  transition: color 0.3s;
}
a:hover {
  color: #16886b;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
}

body,
ul,
li {
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

.wrapper-small {
  max-width: 480px;
  margin: auto;
}

.container {
  padding: 16px;
}

.header {
  text-align: center;
}
.header__title {
  margin: 0 0 8px;
  font-size: 36px;
  font-weight: 800;
}
.header__desc {
  font-size: 16px;
}

.dropzone {
  box-sizing: border-box;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(22, 136, 107, 0.25);
  border: 5px dashed #16886b;
  animation: fadein 0.5s;
}

.form-area {
  text-align: center;
}
.form-area__desc {
  margin: 0 0 16px;
}
.form-area__button {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  background: #eee;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  font-size: 14px;
  color: initial;
  width: 150px;
}
.form-area__button:hover {
  color: initial;
  background: #ddd;
}
.form-area__input {
  display: none;
}

.charts .chart {
  margin: auto;
}
.charts .chart__header {
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  text-align: center;
}
.charts .chart:not(:last-child) {
  margin-bottom: 48px;
}

.transactions {
  max-width: 480px;
  margin: auto auto 36px;
}
.transactions__list {
  list-style: none;
}
.transactions__summary {
  cursor: pointer;
  font-size: 14px;
}
:not(details[open]) .transactions__summary {
  margin-bottom: 8px;
}

.account-summary {
  font-size: 14px;
}
.account-summary:not(:last-child) {
  margin-bottom: 24px;
}

.account {
  margin-bottom: 8px;
}
.account__name {
  font-weight: 600;
}
.account__bank {
  font-size: 14px;
  color: #737479;
}

.owner {
  margin-bottom: 8px;
}
.owner__row {
  display: flex;
}
.owner__row:not(:last-child) {
  margin-bottom: 4px;
}
.owner__row > :first-child {
  font-weight: 600;
  width: 20%;
}
.owner__row > :last-child {
  width: 80%;
}
.owner__info-list {
  list-style: none;
}
.owner__info-list:not(:last-child) {
  margin-bottom: 4px;
}

.transaction {
  font-size: 12px;
}
.transaction__info {
  display: flex;
}
.transaction__categories {
  margin-top: 4px;
  font-size: 10px;
  padding-left: 80px;
}
.transaction__categories span:not(:first-child) {
  margin-left: 4px;
}
.transaction__type {
  border: 1px solid #c2d7d3;
  background: #eefdf7;
  border-radius: 4px;
  padding: 2px;
}
.transaction__category {
  border: 1px solid #ccc;
  background: #eee;
  border-radius: 4px;
  padding: 2px;
}
.transaction__date {
  width: 80px;
  color: #737479;
  text-align: left;
}
.transaction__desc {
  width: calc(100% - 80px - 70px);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transaction__amount {
  width: 70px;
  text-align: right;
}
.transaction__amount.debit {
  color: #16886b;
}
.transaction__amount.credit {
  color: #d4122a;
}
.transaction:not(:last-child) {
  margin-bottom: 8px;
}

.hint {
  color: #737479;
  font-size: 12px;
}

.main {
  flex-grow: 1;
  width: 100%;
}

.header,
.footer {
  flex-shrink: 0;
}

.footer {
  text-align: center;
}
.footer .footer__love {
  color: #f94144;
  font-size: 20px;
  transition: color 0.3s;
  position: relative;
  top: 1px;
}
.footer .footer__love:hover {
  color: #d4122a;
}

[hidden] {
  display: none !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.4b57b1ab.css.map */
